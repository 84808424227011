//////DON'T USE ONLY FOR TESTING////

export default class SignalRClient {
    private _connection: signalR.HubConnection;
    private _callback: Function|null = null;
    private _callbacks:any={};
    //private _promises:any={};


        constructor(url:string) {

        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                transport: signalR.HttpTransportType.WebSockets | 
                           signalR.HttpTransportType.ServerSentEvents | 
                           signalR.HttpTransportType.LongPolling
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        
        this._connection.on("OnMessage", (message: any, requestId: any) => {
            console.log(requestId)
            let parsedMessage = this.tryParseJSON(message);
            //if key
            const vKey = requestId
            //let vKey = Object.keys(parsedMessage);//check how to get key
            console.log(vKey)
            if(vKey && vKey[0]){
                if(this._callbacks[vKey]){
                    // for (let key in parsedMessage) {
                    //     if (parsedMessage[key]?.EOS !== undefined) {
                    //         this._promises[vKey[0]]();
                    //         return;
                    //     }
                    // }
                    this._callbacks[vKey].call(this,parsedMessage);//or just message without request id
                }
            }
            if (this._callback) {
                this._callback(parsedMessage);
            }
        });
        this._connection.onclose(async () => {
            await this.start();
        });

    }

    private async start(): Promise<void> {
        try {
        if (this._connection.state !== signalR.HubConnectionState.Connected) {
            
            await this._connection.start();
            console.log("SignalR Connected.");
        }
        } catch (err) {
            console.error("Connection error: ", err);
            setTimeout(() => this.start(), 5000);
        }
    }

    private tryParseJSON(jsonString: any): any {
        try {
            if (jsonString && typeof jsonString === "object") {
                return jsonString;
            }
            let parsed = JSON.parse(jsonString);
            if (parsed && typeof parsed === "object") {
                return parsed;
            }
        } catch (e) {
            console.warn("JSON parse error: ", e);
        }
        return false;
    }

    public async send(task:string,vOptions:any,pCallback: (message: any) => void): Promise<void> {
        if (!pCallback) {
            console.warn("Message callback is not registered");
        }
        // const vKey = Date.now();
        const vKey = vOptions._requestId;
        console.log(vKey)
        //let vPromiseSuccess;

        if(vKey) {
            this._callbacks[vKey] = pCallback;
        } else {
            this._callback = pCallback;
        }
        // this._promises[vKey] = vPromiseSuccess;
        // let vPromise = new Promise(function(resolve){
        //     vPromiseSuccess = resolve;
        // });

        
        if (this._connection.state !== signalR.HubConnectionState.Connected) {
            await this.start();
        }
        //vOptions['requestID'] = vKey;//adding request id to get it from
        let msg = JSON.stringify(vOptions);
        let subject = new signalR.Subject<string>();
        this._connection.send(task, subject).catch(err => console.error("Send error: ", err));

        let messages = this.chunkString(msg, 1000);
        for (let message of messages) {
            subject.next(message);
        }
        subject.complete();


        //await vPromise;
       
        
    }


    private chunkString(str: string, size: number = 100): string[] {
        let numChunks = Math.ceil(str.length / size);
        let chunks = new Array<string>(numChunks);

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substring(o, o + size);
        }

        return chunks;
    }

    public stopConnection(): Promise<void> {
        this._callback = null;
        this._callbacks = {};
        //this._promises = {};

        return this._connection.stop();
    }
}